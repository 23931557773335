<template>
    <metadata-list name="Bidder" url="/api/bidders" link="bidder" />
</template>

<script>
import MetadataList from '@/components/MetadataList.vue';

export default {
    name: 'BidderList',

    components: {
        MetadataList
    }
};
</script>
